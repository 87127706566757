import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import {ButtonGroup, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useState} from "react";

export function Resume(props) {

    let [emailAddress, setEmailAddress] = useState(props.email)
    let [phoneNumber, setPhoneNumber] = useState(props.phone)
    let [errorMessage, setErrorMessage] = useState(null);
    let [errorPhoneMessage, setErrorPhoneMessage] = useState(null);

    function onClickBack() {
        props.onPreviousToQuestions();
    }

    function onClickNext() {
        let isValid = true;
        if (!isValidEmail(emailAddress)) {
            setErrorMessage('Proszę podać poprawny adres e-mail.');
            isValid = false;
        }
        if (!isValidPhoneNumber(phoneNumber)) {
            setErrorPhoneMessage('Proszę podać poprawny numer telefonu.');
            isValid = false;
        }
        if (isValid){
            props.onChangeEmail(emailAddress)
            props.onChangePhone(phoneNumber)
            props.onEndSession(emailAddress, phoneNumber);
        }
    }

    function onEmailChange(e) {
        setEmailAddress(e.target.value)
        if (isValidEmail(e.target.value)) {
            setErrorMessage('');
        }
    }

    function onPhoneChange(e) {
        setPhoneNumber(e.target.value)
        if (isValidPhoneNumber(e.target.value)) {
            setErrorPhoneMessage('');
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPhoneNumber(phone) {
        const stripped = phone.replace(/\s+/g, '')
        if (/^[0-9\+]{8,13}$/.test(stripped))
            return true;
        return false;
    }

    return (
        <Box sx={{textAlign: 'center'}}>
            <h1>Dziękujemy za wykonanie testu.</h1>
            <h2>Podaj adres e-mail oraz numer telefonu i otrzymaj wynik testu.</h2>

            <FormControl>
                <TextField value={emailAddress} onChange={onEmailChange} sx={{width: 300}} id="address-email" label="Adres e-mail" variant="filled" />
                {errorMessage && <Box sx={{textAlign: 'right', marginTop: 1, color: '#ff0000', fontSize: '0.8rem'}}>{errorMessage}</Box>}
            </FormControl>
            <br/>
            <br/>
            <FormControl>
                <TextField value={phoneNumber} onChange={onPhoneChange} sx={{width: 300}} id="phone-number" label="Numer telefonu" variant="filled" />
                {errorPhoneMessage && <Box sx={{textAlign: 'right', marginTop: 1, color: '#ff0000', fontSize: '0.8rem'}}>{errorPhoneMessage}</Box>}
            </FormControl>

            <br/>
            <br/>
            <br/>
            <p>
                Administratorem danych wprowadzonych do formularza jest Fundacja Rozwoju Przedsiębiorczości "Twój StartUP".<br/>Dane osobowe będą przetwarzane w celu nawiązania kontaktu a także w celach marketingowych. Informujemy o możliwości wycofania zgody. Pełne informacje o przetwarzaniu danych dostępne są w <a href="https://esprit.edu.pl/privacy-policy/" target="__blank">polityce prywatności</a>.
            </p>
            <p>
                Wysłanie formularza jest jednoznaczne z akceptacją <a href="https://esprit.edu.pl/files/regulamin_swiadczenia_uslug.pdf" target="__blank">regulaminu</a>..
            </p>
            <br/>
            <br/>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button onClick={onClickBack} variant="" disableElevation>
                    Wstecz
                </Button>
                <Button onClick={onClickNext} variant="contained" disableElevation>
                    Uzyskaj wynik
                </Button>
            </ButtonGroup>
        </Box>
    );
}