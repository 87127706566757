import Box from "@mui/material/Box";
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
import ApiDataProvider from "../sercices/ApiDataProvider";

export function LanguageChooser(props) {

    let [languagesList, setLanguagesList] = useState(null);
    let [language, setLanguage] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);

    let setValue = (e) => {
        setLanguage((e.target.value))
        return true;
    }

    useEffect(() => {
        if (!languagesList) {
            ApiDataProvider.getLanguages((languages) => {
                setLanguagesList(languages)
            });
        }
    })

    let setLanguageAndChangeMode = () => {
        if (!language) {
            setErrorMessage("Proszę wybrać język.")
            return;
        }
        props.startSession(language)
    }

    return (
        <Box sx={{textAlign: 'center'}}>
            <h1>Wybierz język</h1>
            <h2>Wybierz język i rozpocznij test</h2>
            <FormControl>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={setValue}
                    value={language}
                >
                    {languagesList && languagesList.map(language => {
                        return (
                            <FormControlLabel key={language.id} value={language.id} control={<Radio />} label={language.name} />
                        );
                    })}
                </RadioGroup>
            </FormControl>

                    <br/>
            <p>
                Jeśli nie znasz odpowiedzi na pytanie, pomiń je. "Nie strzelaj!"
            </p>
            <br/>

            <FormControl>

                    <Button onClick={setLanguageAndChangeMode} variant="contained" disableElevation>
                        Rozpocznij test
                    </Button>
            </FormControl>
                    <br/>
            <p>
                    {errorMessage && <Box>{errorMessage}</Box>}
            </p>

        </Box>
    );
}