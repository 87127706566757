export default class ApiDataProvider {

    // static BACKEND_SRC = 'http://127.0.0.1:8000';
    static BACKEND_SRC = 'https://testyback.esprit.edu.pl';

    static languagesList = null;

    static setLanguagesList(languages) {
        ApiDataProvider.languagesList = languages;
    }

    static getLanguages(callback) {
        if (ApiDataProvider.languagesList)
            callback(ApiDataProvider.languagesList)
        else {
            fetch(ApiDataProvider.BACKEND_SRC + '/languages')
                .then((res) => res.json())
                .then((json) => {
                    ApiDataProvider.setLanguagesList(json)
                    callback(ApiDataProvider.languagesList)
                });
        }
    }

    static startSession(languageId, callback) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(ApiDataProvider.BACKEND_SRC + '/test/execution/start/'+languageId, requestOptions)
            .then(response => response.json())
            .then(data => callback(data.sessionId, data.count));
    }

    static getQuestionData(sessionId, questionNumber, callback) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(ApiDataProvider.BACKEND_SRC + '/test/execution/question/'+sessionId+'/'+questionNumber, requestOptions)
            .then(response => response.json())
            .then(data => {
                callback(data)
            });
    }

    static getPreviousReply(sessionId, questionNumber, callback) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
        };
        fetch(ApiDataProvider.BACKEND_SRC + '/test/execution/previousReplies/'+sessionId+'/'+questionNumber, requestOptions)
            .then(response => response.json())
            .then(data => callback(data));
    }

    static saveReply(sessionId, questionNumber, selectedReply, callback) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ replyId: selectedReply })
        };
        fetch(ApiDataProvider.BACKEND_SRC + '/test/execution/reply/'+sessionId+'/'+questionNumber, requestOptions)
            .then(response => response.json())
            .then(data => callback(data));
    }

    static endSession(sessionId, emailAddress, phoneNumber, callback) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: emailAddress, phone_number: phoneNumber })
        };
        fetch(ApiDataProvider.BACKEND_SRC + '/test/execution/end/'+sessionId, requestOptions)
            .then(response => response.json())
            .then(data => callback(data));
    }
}