import Box from "@mui/material/Box";
import * as React from "react";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";

export function End() {

    function onButtonClick() {
        window.location = 'https://esprit.edu.pl'
    }

    return (
        <Box sx={{textAlign: 'center'}}>
            <h1>Dziękujemy za wykonanie testu.</h1>
            <h2>Na Twój adres e-mial wysłaliśmy wynik testu.</h2>

            <br/>
            <br/>
            <ButtonGroup variant="outlined" aria-label="outlined button group">

                <Button onClick={onButtonClick} variant="contained" disableElevation>
                    Zobacz nasze kursy i zapisz się!
                </Button>
            </ButtonGroup>
        </Box>
    );
}